<template>
  <div class="row justify-content-center">
    <customer-header />

    <vs-prompt
      color="primary"
      title=""
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @accept="updateNews"
      @close="close"
      :is-valid="validUpdateNews"
      :active.sync="updatePrompt"
    >
      <div class="con-exemple-prompt">
        กรุณากรอกข้อมูลข่าว

        <vs-input
          type="text"
          icon="event"
          placeholder="หัวข้อข่าว"
          v-model="formModel.topic"
        />
        <vs-textarea
          icon="mode_edit"
          placeholder="รายละเอียด"
          v-model="formModel.desc"
          style="margin-top: 6px"
        />

        <vs-alert :active="!validUpdateNews" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>

    <vs-prompt
      title=""
      @accept="deleteItem"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">ลบข้อมูลนี้?</div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title=""
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @accept="acceptAddNews"
      @close="close"
      :is-valid="validUpdateNews"
      :active.sync="activePromptAddNews"
    >
      <div class="con-exemple-prompt">
        กรุณากรอกข้อมูลข่าวสาร
        <vs-input
          type="text"
          icon="event"
          placeholder="หัวข้อข่าว"
          v-model="formModel.topic"
        />
        <vs-textarea
          icon="mode_edit"
          placeholder="รายละเอียด"
          v-model="formModel.desc"
          style="margin-top: 6px"
        />
        <vs-alert :active="!validUpdateNews" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>

    <div v-if="enable">
      <vs-list>
        <vs-list-item class="k-nav-bar" title="จัดการข่าวสาร" subtitle="">
          <vs-button @click="openAddNews" color="primary" type="filled"
            >เพิ่มข่าวสาร</vs-button
          >
        </vs-list-item>
      </vs-list>
      <vs-card style="margin-top: 10px">
        <div>
          <vs-table stripe :data="items">
            <template slot="thead" style="margin-top: 100px">
              <vs-th>แก้ไข </vs-th>
              <vs-th>ลบ </vs-th>
              <vs-th> วันที่ </vs-th>
              <vs-th> หัวข้อ </vs-th>
              <vs-th> รายละเอียด </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    @click="openUpdatePromt(data[indextr])"
                    color="primary"
                    type="flat"
                    icon="edit"
                  ></vs-button>
                </vs-td>
                <vs-td>
                  <vs-button
                    @click="openDeletePromt(data[indextr].key)"
                    color="primary"
                    type="flat"
                    icon="delete"
                  ></vs-button>
                </vs-td>
                <vs-td :data="data[indextr].create_date">
                  {{
                    new Date(data[indextr].create_date.seconds * 1000)
                      | moment("dddd, Do MMMM YYYY")
                  }}
                </vs-td>
                <vs-td :data="data[indextr].topic">
                  {{ data[indextr].topic }}
                </vs-td>
                <vs-td
                  :data="data[indextr].desc"
                  style="white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;"
                >
                  {{ data[indextr].desc }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <br />
            <paginate
              v-model="page"
              :page-count="total"
              :page-range="3"
              :margin-pages="2"
              :click-handler="inputData"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </vs-card>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
import Paginate from "vuejs-paginate";

console.log("lang", lang);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.component("customer-header", CustomerHeader);
Vue.component("paginate", Paginate);
Vue.component("the-advertising", TheAdvertising);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  name: "ManageHome",
  components: {},
  data() {
    return {
      deletePrompt: false,
      updatePrompt: false,
      allCustomerCode: [],
      language: "en",
      languages: lang,
      customerName: "",
      items: [],
      activePromptAddNews: false,
      selectedId: "",
      formModel: {
        key: "",
        create_date: "",
        topic: "",
        desc: ""
      },
      // pagination
      total: 0, // จำนวนหน้า
      limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,

      user_cloud_config: {},
      enable: null,
      listAdvertising: {}
    };
  },
  computed: {
    validUpdateNews() {
      console.log(this.formModel);
      return (
        this.formModel.topic != null &&
        this.formModel.topic.length > 0 &&
        this.formModel.desc != null &&
        this.formModel.desc.length > 0
      );
      // return true;
    }
  },
  methods: {
    acceptAddNews(color) {
      this.formModel.type = "news";
      const uid = moment(new Date()).unix() + "";
      this.formModel.create_date = new Date();
      this.formModel.dttm = new Date();
      this.formModel.house_no = "";
      this.formModel.request_type = "";

      event.preventDefault();

      db.collection("customer/" + this.customerName + "/request/")
        .doc(uid)
        .set(this.formModel)
        .then(() => {
          this.formModel = {};

          this.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว"
          });
        })
        .catch(error => {
          console.log(error);
        });
      this.page = 1;
    },
    close() {
      this.formModel = {};
    },

    openUpdatePromt(item) {
      console.log("[[openUpdatePromt]]", item);

      this.formModel = item;
      // this.formModel.desc = item.desc;

      this.selectedId = item.key;
      this.updatePrompt = true;
    },

    updateNews(event) {
      console.log("updateNews this.formModel=", this.formModel);
      db.collection("customer/" + this.customerName + "/request")
        .doc(this.selectedId)
        .update(this.formModel)
        .then(() => {
          console.log("updateNews successfully updated!");
          // this.$router.push("/list");
        })
        .catch(error => {
          console.log(error);
        });
    },

    deleteItem() {
      db.collection("customer/" + this.customerName + "/request")
        .doc(this.selectedId)
        .delete()
        .then(() => {
          console.log("Document deleted!");
        })
        .catch(error => {
          console.error(error);
        });
    },

    openAddNews() {
      this.formModel = {};
      this.activePromptAddNews = true;
    },

    openDeletePromt(id) {
      this.selectedId = id;
      this.deletePrompt = true;
    },

    async listItems() {
      let totalCustomer = await db
        .collection("customer/" + this.customerName + "/request")
        .where("type", "==", "news")
        .orderBy("create_date", "desc")
        .get()
        .then(document => document.docs.length);

      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;

      let indexOf = 0;
      let get = db
        .collection("customer/" + this.customerName + "/request")
        .where("type", "==", "news")
        .orderBy("create_date", "desc");
      get
        .get()
        .then(document => {
          let last = document.docs[indexOf];
          db.collection("customer/" + this.customerName + "/request")
            .where("type", "==", "news")
            .orderBy("create_date", "desc")
            .startAt(last)
            .limit(this.limit)
            .onSnapshot(snapshotChange => {
              this.items = [];
              snapshotChange.forEach(doc => {
                this.items.push({
                  key: doc.id,
                  create_date: doc.data().create_date,
                  topic: doc.data().topic,
                  desc: doc.data().desc
                });
                this.isLoad = false;
              });
            });
        })
        .catch(err => {
          console.log("err", err);
        });

      // db.collection("customer/" + this.customerName + "/request")
      //   .where("type", "==", "news")
      //   .orderBy("create_date", "desc")
      //   .onSnapshot((snapshotChange) => {
      //     this.items = [];
      //     snapshotChange.forEach((doc) => {
      //       console.log("doc1",doc.data());
      //       this.items.push({
      //         key: doc.id,
      //         create_date: doc.data().create_date,
      //         topic: doc.data().topic,
      //         desc: doc.data().desc,
      //       });
      //     });
      //   });
    },
    previousData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    nextData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    inputData: function(value) {
      this.page = value;
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    fetchHome: async function({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      const customerName = $cookies.get("customer");
      let get = db
        .collection("customer/" + this.customerName + "/request")
        .where("type", "==", "news")
        .orderBy("create_date", "desc");
      get
        .get()
        .then(document => {
          let last = document.docs[indexOf];
          db.collection("customer/" + this.customerName + "/request")
            .where("type", "==", "news")
            .orderBy("create_date", "desc")
            .startAt(last)
            .limit(this.limit)
            .onSnapshot(snapshotChange => {
              this.items = [];
              snapshotChange.forEach(doc => {
                this.items.push({
                  key: doc.id,
                  create_date: doc.data().create_date,
                  topic: doc.data().topic,
                  desc: doc.data().desc
                });
                this.isLoad = false;
              });
            });
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  },
  components: {
    Datepicker
  },

  created() {
    this.customerName = $cookies.get("customer");
    console.log("customerName", this.customerName);
    this.listItems();

    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.enable = this.user_cloud_config.enable_news;
    this.listAdvertising = ListAdvertising.page_news;
    // console.log("enable_news => " + this.user_cloud_config.enable_news);
    // console.log("data news => ", typeof ListActivity.page_news);
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
.page-item {
}
</style>
